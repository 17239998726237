import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const RecentPosts = () => {
  const { 
    recentlyAllMarkdownRemark: { edges: recentlyBlogs }
  } = useStaticQuery(
    graphql`
      query {
        recentlyAllMarkdownRemark: allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 3
          ) {
            edges {
             node {
               id
               frontmatter {
                 title
               }
               fields {
                slug
               }
             }
            }
          }
      } 
    `
  )

  return (
    <div>
        <h3>Recent Posts</h3>
        <ul>
          {recentlyBlogs.map(
            ({
              node: {
                id,
                frontmatter: { title },
                fields: { slug },
              },
            }) => (
              <li key={id}>
                <Link to={slug}>{title}</Link>
              </li>
            )
          )}
        </ul>
      </div>
  )
}

export default RecentPosts